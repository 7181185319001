<template>
  <CFooter :fixed="false">
    <div>
      <!-- <a href="javascript:void(0)" target="_blank">Template Front</a> -->
      <p>Todos los derechos reservados<span class="ml-1">&copy; {{new Date().getFullYear()}}.</span></p>
      <!-- <span class="ml-1">&copy; {{new Date().getFullYear()}} .</span> -->
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Desarrollado por</span>
      <a href="https://howertpi.com/" target="_blank">HOWERTPI</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
